const WEB_API =	"https://us-central1-medivic-dev.cloudfunctions.net/webApi/api/v1";
export const BROADCAST_TOPIC = "TO_ALL_USERS";
export const BROADCAST_NOTIFICATION_URL =
  WEB_API + "/sendBoradcastNotification";
export const CREATE_DOCTOR_URL = WEB_API + "/createDoctor";
export const DELETE_DOCTOR_URL = WEB_API + "/deleteDoctor";
export const CREATE_ADMIN_URL = WEB_API + "/createAdmin";
export const CURRENCY = 'R';

export const PLACEHOLDER_IMAGE_URL =  "https://firebasestorage.googleapis.com/v0/b/medivic-dev.appspot.com/o/placeholder-img.jpg?alt=media&token=30cf4e42-c510-49e0-8ef1-ba1df82d1e88";

// Initialize Firebase settings
export const FIREBASE_CONFIG = {
apiKey: "AIzaSyDXJIpumtJfNgwLciGBbxbD4GtFAAPhflY",
    authDomain: "medivic-dev.firebaseapp.com",
    databaseURL: "https://medivic-dev.firebaseio.com",
    projectId: "medivic-dev",
    storageBucket: "medivic-dev.appspot.com",
    messagingSenderId: "914985889638",
    appId: "1:914985889638:web:74a4820a510ae621c162a7",
    measurementId: "G-XJ5KLVL7ZE"
};

