import { firestore, auth } from "../../firebase/firebase";
import firebase from "firebase";
import CreateDate from "util/createDate";
/**
 *
 * Class handle all the firestore queries
 *
 
 */
 

export default class FirestoreService {
  // ***************************************************
  // Get data from the database.
  // ***************************************************



  /**
   *
   * Get the users for the Users screen.
   *
   */
  static async getAdmins() {
    let ref = firestore
	.collection("admin")
      .doc("admin")
      .collection("users")
      .orderBy("role", "asc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }
        let obj = {
          data: [],
          lastDocument: null,
        };
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];
		snapshot.forEach((doc) => {
			
          obj.data.push(doc.data());
        });
		return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }
  
  
  
  static async getUsers() {
    let ref = firestore
      .collection("users")
      .orderBy("createdAt", "desc")
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
		  
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        // Return the last document for next query.
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get more users for the Users screen.
   *
   * @param {firebase.firestore.DocumentSnapshot} startAfter - The number of documents to skip while getting
   * new users batch.
   *
   */
  static async getMoreUsers(startAfter) {
    let ref = firestore
      .collection("users")
      .orderBy("timestamp", "desc")
      .startAfter(startAfter)
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        

        // Return the last document for next query.
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get single user for the small card info.
   *
   * @param {String} userId UserId for which the document is to be retrieved.
   *
   */
  static async getSingleUser(userId) {
    let ref = firestore.collection("users").where("uid", "==", userId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data to send in object format
        let obj = {
          success: true,
          data: {},
        };

        snapshot.forEach((doc) => {
          obj.data = doc.data();
        });

        return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the doctors for the Doctors screen.
*
*
* 
   */
  static async getDoctors() {
    let ref = firestore
      .collection("doctors")
      .orderBy("timestamp", "desc")
	  .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        // Return the last document for next query.
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
	  
    return result;
  }

  /**
   *
   * Get more doctors for the Doctors screen.
   *
   * @param {firebase.firestore.DocumentSnapshot} startAfter - The document snapshot to start after
   *
   */
  static async getMoreDoctors(startAfter) {
    
    let ref = firestore
      .collection("doctors")
      .orderBy("timestamp", "desc")
      .startAfter(startAfter)
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        // Return the last document for next query.
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get single doctor for the small card info.
   *
   * @param {String} doctorId DoctorId for which the document is to be retrieved.
   *
   */
  static async getSingleDoctor(doctorId) {
    let ref = firestore.collection("doctors").where("uid", "==", doctorId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data to send in object format
        let obj = {
          success: true,
          data: {},
        };

        snapshot.forEach((doc) => {
          obj.data = doc.data();
        });

        return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;
  }


   static async getAccountHolderName(doctorId) {
	  
    let ref = firestore.collection("doctors").where("uid", "==", doctorId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }
	      let obj : '';
        snapshot.forEach((doc) => {
			obj = doc.data().bankAccHolderName;
        });
    return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;

  }
  
   static async getPayouts() { 
   var holderAccNameN = {};
   var accNumberN = {};
		var accTypeN = {};
		var branchCodeN	 = {};
		var branchNameN	 = {};
		var bankNameN	 = {};
    let ref1 = firestore.collection("doctors");
    let result1 = await ref1
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }
	      let obj1 : '';
		snapshot.forEach((doc) => {
			
			holderAccNameN[doc.data().uid]=doc.data().bankAccHolderName;
			accNumberN[doc.data().uid]=doc.data().bankAccNumber;
			accTypeN[doc.data().uid]=doc.data().bankAccType;
			branchCodeN[doc.data().uid]=doc.data().bankBranchCode;
			branchNameN[doc.data().uid]=doc.data().bankBranchName;
			bankNameN[doc.data().uid]=doc.data().bankName;
			
		});
    return obj1;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    
	
	var currDate = new Date();
	currDate.setHours(0,0,0,0);
	
	let ref = firestore.collection("payments").where("paid", "==", false).orderBy("doctorName", "asc").limit(1000);
	//let ref = firestore.collection("payments").orderBy("doctorName", "asc").limit(1000);
    let result = await ref.get().then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }
        let obj = {
          data: [],
          lastDocument: null,
        };
		var holder = {};
		var holder2 = {};
		var uid = {};
		var docNameHolder = {};
		var accNameHolder = {};
		var accNumber = {};
		var accType = {};
		var branchCode	 = {};
		var branchName	 = {};
		var bankName	 = {};
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];
		snapshot.forEach(async(doc) => {
			;
			let d=doc.data();
			let accHolderName="";
			
			if(doc.data().createdAt<currDate){
				
				if (holder2.hasOwnProperty(d.doctorId)) {
				  holder[d.doctorId] = holder[d.doctorId] + (d.fee*0.8);
				  uid[d.doctorId]=uid[d.doctorId]+","+doc.id;
				  
				  holder2[d.doctorId]=holder2[d.doctorId]+1;
				  docNameHolder[d.doctorId]=d.doctorName;
				  accNameHolder[d.doctorId]=accHolderName;
				  
			  } else {
				  uid[d.doctorId]=doc.id;
				  holder[d.doctorId] = (d.fee*0.8);
				  holder2[d.doctorId] = 1;
				  docNameHolder[d.doctorId]=d.doctorName;
				  accNameHolder[d.doctorId]=accHolderName;
				  
			  }
			}
        });
		let cntI=0;
		for (var prop in holder) {
			cntI=cntI+1;
		  obj.data.push({ doctorId: prop, fee: holder[prop].toFixed(2), count: holder2[prop],doctorName: docNameHolder[prop],doctorAccName: holderAccNameN[prop],accNumber:accNumberN[prop],accType:accTypeN[prop],branchCode:branchCodeN[prop],branchName:branchNameN[prop],bankName:bankNameN[prop],uidPayout:uid[prop],ref:CreateDate.dmyRef()+cntI,stmtRef:'PeachPayments'		});
		  
		}
		
        return obj;
      })
      .catch((e) => {
		  
        return { error: e.toString() };
      });
     return { success: true };
  }
  


static async getSingleReview(doctorId) {
	let ref = firestore.collection("doctors").doc(doctorId).collection("ratings");
    let result = await ref
      .get()
      .then((snapshot) => {
		  
        if (snapshot.empty) {
			return { noData: true };
        }
        // convert data send in list format
        let obj = {
          data: {
			  data1:[],
			  name:[]
			  
		  },
          lastDocument: null,
        };
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];
        snapshot.forEach( async(doc) => {
			let myVal=await this.getSingleUserName(doc.data().patientId);
			doc.data().patientName=myVal;
			obj.data.data1.push(doc.data());
			obj.data.name.push(myVal);
        });

        return obj;
      })
      .catch((e) => {
		  
        return { error: e.toString() };
      });
    return result;
  }
  
  static async getSingleUserName(userId) {
	  
    let ref = firestore.collection("users").where("uid", "==", userId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }
		// convert data to send in object format
        let obj : '';
        

        snapshot.forEach((doc) => {
			obj = doc.data().name;
        });

        return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the hospitals for the Hospitals screen.
   *
   */
  static async getHospitals() {
    let ref = firestore
      .collection("hospitals")
      .orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get more hospitals for the Hospitals screen.
   *
   * @param {firebase.firestore.DocumentSnapshot} startAfter - The doc snapshot to start query after
   *
   */
  static async getMoreHospitals(startAfter) {
    let ref = firestore
      .collection("hospitals")
      .orderBy("timestamp", "desc")
      .startAfter(startAfter)
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the blogs for the blogs screen.
   *
   */
  static async getBlogs() {
    let ref = firestore
      .collection("blogs")
      .orderBy("timestamp", "desc")
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }
  /**
   *
   * Get more blogs for the blogs screen.
   *
   * @param {firebase.firestore.DocumentSnapshot} startAfter - The document to start the query
   *
   */
  static async getMoreBlogs(startAfter) {
    let ref = firestore
      .collection("blogs")
      .orderBy("timestamp", "desc")
      .startAfter(startAfter)
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }


/**
   *
   * Get the FAQ for the faq screen.
   *
   */
  static async getFaq() {
    let ref = firestore
      .collection("faq")      
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get more faq for the faq screen.
   *
   * @param {firebase.firestore.DocumentSnapshot} startAfter - The document to start the query
   *
   */
  static async getMoreFaq(startAfter) {
    let ref = firestore
      .collection("faq")
      .startAfter(startAfter)
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }



  /**
   *
   * Gets all the notifications for the admin to broadcast to the users
   *
   */
  static async getNotifications() {
    let ref = firestore
      .collection("admin")
      .doc("notifications")
      .collection("notifications")
      .orderBy("timestamp", "desc");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the appointments for the Appointments screen.
   *
   */
  static async getAppointments() {
    let ref = firestore
      .collection("appointments")
      .orderBy("createdAt", "desc")
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

   /**
   *
   * Get the appointments for the Appointments screen.
   *
   */
  static async getAppointmentDash() {
	  var up1=0 ,cn1=0 ,dn1=0 ;
	  var up2=0 ,cn2=0 ,dn2=0 ;
	  var up3=0 ,cn3=0 ,dn3=0 ;
	  var up4=0 ,cn4=0 ,dn4=0 ;
	  var up5=0 ,cn5=0 ,dn5=0 ;
	  var up6=0 ,cn6=0 ,dn6=0 ;
	  var up7=0 ,cn7=0 ,dn7=0 ;
	  var up8=0 ,cn8=0 ,dn8=0 ;
	  var up9=0 ,cn9=0 ,dn9=0 ;
	  var up10=0,cn10=0,dn10=0;
	  var up11=0,cn11=0,dn11=0;
	  var up12=0,cn12=0,dn12=0;
    let ref = firestore
      .collection("appointments")
      .orderBy("createdAt", "desc")
      ;
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };
		
		var upList = [];
		var dnList = [];
		var cnList = [];
		
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
		  
		  if(CreateDate.month(doc.data().createdAt)=='1'){
			if (doc.data().status=='Upcoming'){
				up1+=1;
			}else if (doc.data().status=='Completed'){
				dn1+=1;
			}
			else {
				cn1+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='2'){
			if (doc.data().status=='Upcoming'){
				up2+=1;
			}else if (doc.data().status=='Completed'){
				dn2+=1;
			}
			else {
				cn2+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='3'){
			if (doc.data().status=='Upcoming'){
				up3+=1;
			}else if (doc.data().status=='Completed'){
				dn3+=1;
			}
			else {
				cn3+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='4'){
			if (doc.data().status=='Upcoming'){
				up4+=1;
			}else if (doc.data().status=='Completed'){
				dn4+=1;
			}
			else {
				cn4+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='5'){
			if (doc.data().status=='Upcoming'){
				up5+=1;
			}else if (doc.data().status=='Completed'){
				dn5+=1;
			}
			else {
				cn5+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='6'){
			if (doc.data().status=='Upcoming'){
				up6+=1;
			}else if (doc.data().status=='Completed'){
				dn6+=1;
			}
			else {
				cn6+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='7'){
			if (doc.data().status=='Upcoming'){
				up7+=1;
			}else if (doc.data().status=='Completed'){
				dn7+=1;
			}
			else {
				cn7+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='8'){
			if (doc.data().status=='Upcoming'){
				up8+=1;
			}else if (doc.data().status=='Completed'){
				dn8+=1;
			}
			else {
				cn8+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='9'){
			if (doc.data().status=='Upcoming'){
				up9+=1;
			}else if (doc.data().status=='Completed'){
				dn9+=1;
			}
			else {
				cn9+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='10'){
			if (doc.data().status=='Upcoming'){
				up10+=1;
			}else if (doc.data().status=='Completed'){
				dn10+=1;
			}
			else {
				cn10+=1;
			}
		  }
		  else if(CreateDate.month(doc.data().createdAt)=='11'){
			if (doc.data().status=='Upcoming'){
				up11+=1;
			}else if (doc.data().status=='Completed'){
				dn11+=1;
			}
			else {
				cn11+=1;
			}
		  }
		  else{
			if (doc.data().status=='Upcoming'){
				up12+=1;
			}else if (doc.data().status=='Completed'){
				dn12+=1;
			}
			else {
				cn12+=1;
			}
		  }
        });
		
		
	//	upList.push({value: up1});
	//	upList.push({value: up2});
	//	upList.push({value: up3});
	//	upList.push({value: up4});
	//	upList.push({value: up5});
	//	upList.push({value: up6});
	//	upList.push({value: up7});
	//	upList.push({value: up8});
		upList.push({value: up9});
		upList.push({value: up10});
		upList.push({value: up11});
		upList.push({value: up12});
		
	//	dnList.push({value: dn1});
	//	dnList.push({value: dn2});
	//	dnList.push({value: dn3});
	//	dnList.push({value: dn4});
	//	dnList.push({value: dn5});
	//	dnList.push({value: dn6});
	//	dnList.push({value: dn7});
	//	dnList.push({value: dn8});
		dnList.push({value: dn9});
		dnList.push({value: dn10});
		dnList.push({value: dn11});
		dnList.push({value: dn12});
		
	//	cnList.push({value: cn1});
	//	cnList.push({value: cn2});
	//	cnList.push({value: cn3});
	//	cnList.push({value: cn4});
	//	cnList.push({value: cn5});
	//	cnList.push({value: cn6});
	//	cnList.push({value: cn7});
	//	cnList.push({value: cn8});
		cnList.push({value: cn9});
		cnList.push({value: cn10});
		cnList.push({value: cn11});
		cnList.push({value: cn12});
		
		obj.data.push(upList);
		obj.data.push(dnList);
		obj.data.push(cnList);
		
		
		//obj.data.push(doc.data());
        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }
   /**
   *
   * Get Company Info for the Appointments screen.
   *
   *
   */
	static async getCompanyInfo() {

    let ref = firestore
      .collection("htmlcontent")
      ;
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };
		
        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
			
          obj.data.push(doc.data());
        });
		
        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get more appointments for the Appointments screen.
   *
   * @param {firebase.firestore.DocumentSnapshot} startAfter - Start the query after this document
   *
   */
  static async getMoreAppointments(startAfter) {
    let ref = firestore
      .collection("appointments")
      .orderBy("createdAt", "desc")
      .startAfter(startAfter)
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get single appointment.
   *
   * @param {String} appointmentId ID for which the document is to be retrieved.
   *
   */
  static async getSingleAppointment(appointmentId) {
    let ref = firestore.collection("appointments").doc(appointmentId);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data to send in object format
        let obj = {
          success: true,
          data: {},
        };

        obj.data = snapshot.data();

        return obj;
      })
      .catch((e) => {
        return { error: true, message: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the payments for the payments screen.
   *
   */
  static async getName(doctorId) { 
	
	return doctorId;
  }
  static async getPayments() { 
  
	let ref = firestore
      .collection("payments")
      .orderBy("createdAt", "desc")
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get more payments for the Payments screen.
   *
   * @param {firebase.firestore.DocumentSnapshot} startAfter - Start query after this document
   *
   */
  static async getMorePayments(startAfter) {
    let ref = firestore
      .collection("payments")
      .orderBy("createdAt", "desc")
      .startAfter(startAfter)
      .limit(100);
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };

        obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];

        snapshot.forEach((doc) => {
          obj.data.push(doc.data());
        });

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Get the list of illness from the database.
   *
   */
  static async getIllnessList() {
    let ref = firestore.collection("admin").doc("illnessList");
    let result = await ref
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          return { noData: true };
        }

        // convert data send in list format
        let obj = {
          data: [],
        };

        obj.data = snapshot.data()["illnessList"];

        return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }

  /**
   *
   * Add illness values to the list of illness
   * @param {Array<String>} values an array of string to add to the list.
   *
   */
  static async addIllness(values) {
    let ref = firestore.collection("admin").doc("illnessList");
    let result = await ref
      .update({
        illnessList: firebase.firestore.FieldValue.arrayUnion(...values),
      })
      .then((_) => {
        
        return { success: true };
      })
      .catch((e) => {
        
        return { error: true, errorText: e.toString() };
      });
    return result;
  }

  /**
   *
   * Delete illness value from the list of illness
   * @param {String} value a string to delete from the list.
   *
   */
  static async deleteIllness(values) {
    let ref = firestore.collection("admin").doc("illnessList");
    let result = await ref
      .update({
        illnessList: firebase.firestore.FieldValue.arrayRemove(...values),
      })
      .then((_) => {
        
        return { success: true };
      })
      .catch((e) => {
        
        return { error: true, errorText: e.toString() };
      });
    return result;
  }

  /**
   *
   * Checks if the email is allowed to go into the admin panel or not.
   *
   */
  static async checkAdminUser(email) {
    const query = firestore
      .collection("admin")
      .doc("admin")
      .collection("users")
      .where("email", "==", email);
    const result = await query
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          
		  
          return { success: true, payload: snapshot.docs[0].data() };
        } else {
          
          return { error: true, message: "Entry not allowed" };
        }
      })
      .catch((e) => {
        
        return { error: true, message: e.toString() };
      });
    return result;
  }

  // ***************************************************
  // Functions to adds data to the database
  // ***************************************************

  /**
   * Creates a notification to broadcast to the users.
   *
   * @param {Map<String, String>} data document data to set
   *
   */
   static async writeLog(upLog) {
	
    let ref = firestore.collection("eventLogs").doc();
    
	upLog.uid=ref.id;
	
    let result = await ref
      .set(upLog)
      .then(() => {
        return { success: true, payload: upLog };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
  
  static async backupDoctor(data) {
	
    let ref = firestore.collection("backupDoctor").doc();
    
	data.uid=ref.id;
	
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
  
  static async createNotification(data) {
    let ref = firestore
      .collection("admin")
      .doc("notifications")
      .collection("notifications")
      .doc();
    data.uid = ref.id;
    data.timestamp = new Date().getTime().toString();
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
  }

  
  static async updatePayout(sendData) {
let ref="";	
	sendData.forEach(async(eleData) =>{
		
		 ref = firestore
		  .collection("payments")
		  .doc(eleData.uid);
		  let result = await ref
		  .get()
		  .then((snapshot) => {
			if (snapshot.empty) {
				console.log("Empty");
			  return { noData: true };
			}
				console.log("Data");
				let a=snapshot.data();
				a.reference=eleData.ref;
				a.admin=localStorage.getItem("email");
				a.paid=true;
				snapshot.ref.update(a);
			/*snapshot.forEach(async(doc) => {
				console.log("Data");
				let a=doc.data();
				a.reference=eleData.ref;
				a.paid=true;
				doc.ref.update(a);
			});*/
			let obj = {
          success: true,
          data: {},
        };
			return obj;
		  })
		  .catch((e) => {
			  console.log("Error: "+e.toString());
			return { error: e.toString() };
		  });
	});
    return ref;
  }
  
  static async deleteNotification(uid) {
    let ref = firestore
      .collection("admin")
      .doc("notifications")
      .collection("notifications")
      .doc(uid);
    let result = await ref
      .delete()
      .then(() => {
        return { success: true };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
  }

  /**
   *
   * Add new hospital to the database
   *
   * @param {Map<String, String>} data Map Object which contains the data for the document.
   *
   */
  static async addNewHospital(data) {
    let ref = firestore.collection("hospitals").doc();
    data.uid = ref.id;
    data.timestamp = new Date().getTime().toString();
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
  }
  
 static async addAdmin(data,mypass) {
	 
    firebase
     .auth()
     .createUserWithEmailAndPassword(data.email, mypass)
     .then((user) => {
       
     })
     .catch((error) => {
       
     });
	 
	  //let ref = firestore.collection("admin").doc();
	  let ref = firestore
      .collection("admin")
      .doc("admin")
      .collection("users").doc();
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
  }
  /**
   *
   * Add  & GET  new HTML Content to the database
   *
   * @param {Map<String, String>} data Map Object which contains the data for the document.
   **/
  static async addNewHtmlcontent(data) {
	
	let ref = firestore.collection("htmlcontent").doc(data.uid);
    
    data.timestamp = new Date().getTime().toString();
    let result = await ref
      .update(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: e.toString() };
      });

    return result;
	
  }
  
  static async getHtmlcontent() {
    let ref = firestore
      .collection("htmlcontent")
      .limit(1);
    let result = await ref
      .get()
      .then((snapshot) => {
		  
        if (snapshot.empty) {
          return { noData: true };
        }
		// convert data send in list format
        let obj = {
          data: [],
          lastDocument: null,
        };
		obj.lastDocument = snapshot.docs[snapshot.docs.length - 1];
		obj.data.push(snapshot.docs[snapshot.docs.length - 1].data());
		return obj;
      })
      .catch((e) => {
        return { error: e.toString() };
      });
    return result;
  }
  
  

  static async addNewBlog(data) {
    let ref = firestore.collection("blogs").doc();
    data.uid = ref.id;
    data.timestamp = new Date().getTime().toString();
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
  
  static async addNewFaq(data) {
    let ref = firestore.collection("faq").doc();
    data.uid = ref.id;
    let result = await ref
      .set(data)
      .then(() => {
        return { success: true, payload: data };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }

  // ***************************************************
  // Functions to update database
  // ***************************************************

  static async updatePassword(newPassword) {
    let user = auth.currentUser;
    let result = await user
      .updatePassword(newPassword)
      .then(function() {
        // Update successful.
        return { success: true };
      })
      .catch(function(error) {
        // An error happened.
        return { error: true, errorText: error.toString() };
      });

    return result;
  }

  // TODO: Update the admin values as well and TEST IT
  static async updateEmail(newEmail) {
    let user = auth.currentUser;
    const oldEmail = user.email;

    let result = await user
      .updateEmail(newEmail)
      .then(async (_) => {
        

        // update email in the admin document
        const query = firestore
          .collection("admin")
          .doc("admin")
          .collection("users")
          .where("email", "==", oldEmail);

        let r = await query
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length > 0) {
              

              snapshot.forEach(async (doc) => {
                await doc.ref
                  .update({ email: newEmail })
                  .then((_) => {
              
                  })
                  .catch((e) => {
                    
                  });
              });
            } else {
              
            }
          })
          .catch((e) => {
            
          });

        localStorage.setItem("email", newEmail);
        // Update successful.
        return { success: true };
      })
      .catch((error) => {
        // An error happened.
        return { error: true, errorText: error.toString() };
      });

    return result;
  }

  static async updateHospital(hospitalObject) {
    const ref = firestore.collection("hospitals").doc(hospitalObject.uid);
    let result = await ref
      .update(hospitalObject)
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
  
	
  static async updateDoctor(doctorObject) {
    const ref = firestore.collection("doctors").doc(doctorObject.uid);
    let result = await ref
      .update(doctorObject)
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
  static async updateUser(userObject) {
    const ref = firestore.collection("users").doc(userObject.uid);
    let result = await ref
      .update(userObject)
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
	
  static async updateBlog(blogObject) {
    const ref = firestore.collection("blogs").doc(blogObject.uid);
    let result = await ref
      .update(blogObject)
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }

	static async updateFaq(faqObject) {
    const ref = firestore.collection("faq").doc(faqObject.uid);
    let result = await ref
      .update(faqObject)
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }



  // ****************************************************
  // Delete functions
  // ****************************************************

  static async deleteHospital(hospitalObject) {
    const ref = firestore.collection("hospitals").doc(hospitalObject.uid);
    let result = await ref
      .delete()
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }

  static async deleteBlog(blogObject) {
    const ref = firestore.collection("blogs").doc(blogObject.uid);
    let result = await ref
      .delete()
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
  
  static async deleteFaq(faqObject) {
    const ref = firestore.collection("faq").doc(faqObject.uid);
    let result = await ref
      .delete()
      .then((_) => {
        return { success: true };
      })
      .catch((e) => {
        return { error: true, errorText: e.toString() };
      });

    return result;
  }
  
  
}

/**
 *
 * Class that is just a mock test for users to see the panel
 * but not actually maniputale the data.
 *
 */
class MockTest {
  static mockMessage =
    "This is a demo and you are not allowed to perform this action.";

  /**
   * Creates a notification to broadcast to the users.
   *
   * @param {Map<String, String>} data document data to set
   *
   */
  static async createNotification() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async deleteNotification() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async addNewHospital() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }


static async addNewHtmlcontent() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async addNewBlog() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async addNewFaq() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  // ***************************************************
  // Functions to update database
  // ***************************************************

  static async updatePassword() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async updateEmail() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async updateHospital() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

static async updateDoctor() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async updateBlog() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async updateFaq() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }
  
  // ****************************************************
  // Delete functions
  // ****************************************************

  static async deleteHospital() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }

  static async deleteBlog() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }
  
  static async deleteFaq() {
    alert(this.mockMessage);
    return Promise.resolve({ error: true, errorText: this.mockMessage });
  }
}
